
/** GENERAL APP STYLES **/

.page-app {
  .main-app {
    min-height: 0;
    height: calc(100vh - #{$height-header});
  }

  .main-footer {
    margin-top: 0;
    border-top-width: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 15px;
    padding-bottom: 5px;

    @include media-breakpoint-up(sm) {
      flex-direction: row;
      padding-top: 20px;
      padding-bottom: 0;
    }

    span + span {
      margin-top: 2px;
      @include media-breakpoint-up(sm) { margin-top: 0; }
    }
  }

  .header-link {
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $secondary;
    font-size: 18px;
    @include transition(all 0.25s);

    &:hover,
    &:focus {
      color: $primary;
    }
  }

  .dropdown-menu {
    border-color: rgba($border-color, .6);
    border-width: 0;
    border-radius: 6px;
    @include shadow-sharp($gray-400);
  }

  .dropdown-item {
    display: flex;
    align-items: center;
    padding: 7px 10px;

    i {
      font-size: 18px;
      line-height: 1;
      margin-right: 10px;
    }
  }
}
//quill font
///https://github.com/zenoamaro/react-quill/issues/273
.ql-picker.ql-font {
  .ql-picker-item {
    font-size: 0;
    &:before {
      content: attr(data-value) !important;
      font-size: 14px;
    }
  }
}

.ql-picker.ql-font{
  .ql-active {
    &:before {
      content: attr(data-value) !important;
      font-size: 14px;
    }
  }
}

.ql-picker.ql-font .ql-picker-label[data-value="Roboto"]::before, 
.ql-picker.ql-font .ql-picker-item[data-value="Roboto"]::before
{
    font-family: "Roboto", cursive;;
    content: "Roboto" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="Raleway"]::before, 
.ql-picker.ql-font .ql-picker-item[data-value="Raleway"]::before
{
    font-family: "Raleway", cursive;;
    content: "Raleway" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="Montserrat"]::before, 
.ql-picker.ql-font .ql-picker-item[data-value="Montserrat"]::before
{
    font-family: "Montserrat", cursive;;
    content: "Montserrat" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="Lato"]::before, 
.ql-picker.ql-font .ql-picker-item[data-value="Lato"]::before
{
    font-family: "Lato", cursive;;
    content: "Lato" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="Rubik"]::before, 
.ql-picker.ql-font .ql-picker-item[data-value="Rubik"]::before
{
    font-family: "Rubik", cursive;;
    content: "Rubik" !important;
}

/* Set content font-families */
.ql-font-Roboto {
  font-family: "Roboto";
}
.ql-font-Raleway {
  font-family: "Raleway";
}
.ql-font-Montserrat {
  font-family: "Montserrat";
}
.ql-font-Lato {
  font-family: "Lato";
}
.ql-font-Rubik {
  font-family: "Rubik";
}

// .ql-color .ql-picker-options [data-value=custom-color] {
//   background: none !important;
//   width: 100% !important;
//   height: 20px !important;
//   text-align: center;
// }
// .ql-color .ql-picker-options [data-value=custom-color]:before {
//   content: 'Custom Color';
// }
// .ql-color .ql-picker-options [data-value=custom-color]:hover {
//   border-color: transparent !important;
// }
